
import CommonLayout from 'containers/AccountPage/CommonLayout';
import React from 'react'
import { Helmet } from "react-helmet";

type Props = {
    className?: string
}

const BookingsPage = ({ className }: Props) => {
    return (
        <div className={`nc-BookingsPage ${className}`} data-nc-id="BookingsPage">
            <Helmet>
                <title>Account || Booking React Template</title>
            </Helmet>
            <CommonLayout>
                
            </CommonLayout>
        </div>
    )
}

export default BookingsPage