import { message } from 'antd';
import axios from '../services/api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DateContext = React.createContext({
    bookingLocation: '',
    setBookingLocation: (value: string) => {},
    availableLocations: [],
    setAvailableLocations: (value: any) => {},
});
export interface DateRage {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
}
export interface TimeRage {
    startTime: string;
    endTime: string;
}

export const defaultStartDate = () => {
    var dt = new Date(new Date().setHours(new Date().getMinutes() > 0 ? new Date().getHours() + 1 : new Date().getHours())).setMinutes(0, 0, 0);
    // var dt = new Date(Date.now()).setMinutes(0, 0, 0);
    // hours > 8 pm set to 8 am next day or hours < 8 am then set to 8 am
    if (new Date(dt).getHours() > 20) {
        dt = new Date(dt).setHours(8, 0, 0, 0);
        dt = new Date(dt).setDate(new Date(dt).getDate() + 1);
    } else if (new Date(dt).getHours() < 8) {
        dt = new Date(dt).setHours(8, 0, 0, 0);
    }
    return dt;
};

export const defaultEndDate = () => {
    var dt = new Date(Date.now()).setMinutes(0, 0, 0);
    if (new Date(dt).getHours() > 20 || new Date(dt).getHours() < 8) {
        dt = new Date(dt).setHours(20, 0, 0, 0);
    }
    return dt + 86400000;
};

export const DateProvider = ({ children }: any) => {
    const [dateRangeValue, setDateRangeValueRaw] = useState<DateRage>({
        startDate: moment(new Date(new Date().setHours(new Date().getMinutes() > 0 ? new Date().getHours() + 1 : new Date().getHours())).setMinutes(0, 0, 0)),
        endDate: moment(Date.now() + 86400000),
    });
    const [timeRangeValue, setTimeRangeValueRaw] = useState<TimeRage>({
        startTime: '10:00 AM',
        endTime: '10:00 AM',
    });
    const [bookingLocation, setBookingLocation] = useState<string>('');
    const [availableLocations, setAvailableLocations] = useState<any>([]);
    const setDateRangeValue = (value: DateRage) => {
        // if value.startDate is less than current time then message.error("Start date must be greater than current time")
        if (value.startDate && value.endDate) {
            // if (value.startDate.isBefore(moment())) {
            //     message.error("Start date must be greater than current time");
            //     return;
            // }
            // if (value.endDate.isBefore(value.startDate)) {
            //     message.error("End date must be greater than start date");
            //     return;
            // }
            if (value.startDate !== dateRangeValue.startDate) {
                setTimeRangeValueRaw({
                    // current time in h:mm A rounded to next hour also 8:00 PM if current time is greater than 8:00 PM
                    startTime: timeRangeValue.startTime,
                    endTime: timeRangeValue.endTime,
                });
            }
            if (value.endDate !== dateRangeValue.endDate) {
                setTimeRangeValueRaw({
                    endTime: '8:00 PM',
                    startTime: timeRangeValue.startTime,
                });
            }
            setDateRangeValueRaw(value);
        }
    };
    const setTimeRangeValue = (value: TimeRage) => {
        // if dateRangeValue.startDate === current date then check time
        // if value.startTime is less than current time then message.error("Start time must be greater than current time")
        if (value.startTime && value.endTime && dateRangeValue.startDate && dateRangeValue.endDate) {
            if (
                dateRangeValue?.startDate
                    .set({
                        hour: moment(value.startTime, 'h:mm A').hour(),
                        minute: moment(value.startTime, 'h:mm A').minute(),
                        second: moment(value.startTime, 'h:mm A').second(),
                    })
                    .toDate() < new Date()
            ) {
                message.error('Start time must be greater than current time');
                console.log(value);

                return;
            }
            if (dateRangeValue?.startDate.isSame(dateRangeValue.endDate, 'day')) {
                // message.success("Time range value changed");
                // console.log(dateRangeValue?.startDate.(value.startTime, "h:mm A").toDate());
                // dateRangeValue.startDate set time to value.startTime hh:mm A

                console.log(
                    dateRangeValue?.startDate
                        .set({
                            hour: moment(value.startTime, 'h:mm A').hour(),
                            minute: moment(value.startTime, 'h:mm A').minute(),
                            second: moment(value.startTime, 'h:mm A').second(),
                        })
                        .toDate(),
                    dateRangeValue?.endDate
                        .set({
                            hour: moment(value.endTime, 'h:mm A').hour(),
                            minute: moment(value.endTime, 'h:mm A').minute(),
                            second: moment(value.endTime, 'h:mm A').second(),
                        })
                        .toDate(),
                    dateRangeValue?.endDate
                        .set({
                            hour: moment(value.endTime, 'h:mm A').hour(),
                            minute: moment(value.endTime, 'h:mm A').minute(),
                            second: moment(value.endTime, 'h:mm A').second(),
                        })
                        .isBefore(
                            dateRangeValue?.startDate.set({
                                hour: moment(value.startTime, 'h:mm A').hour(),
                                minute: moment(value.startTime, 'h:mm A').minute(),
                                second: moment(value.startTime, 'h:mm A').second(),
                            })
                        )
                );
                if (
                    dateRangeValue?.endDate
                        .set({
                            hour: moment(value.endTime, 'h:mm A').hour(),
                            minute: moment(value.endTime, 'h:mm A').minute(),
                            second: moment(value.endTime, 'h:mm A').second(),
                        })
                        .isSameOrBefore(
                            dateRangeValue?.startDate.set({
                                hour: moment(value.startTime, 'h:mm A').hour(),
                                minute: moment(value.startTime, 'h:mm A').minute(),
                                second: moment(value.startTime, 'h:mm A').second(),
                            })
                        )
                ) {
                    message.error('Start time must be greater than end time');
                    return;
                }
            }
            // if (moment(value.endTime, "hh:mm A").isBefore(moment(value.startTime, "hh:mm A"))) {
            //     message.error("End time must be greater than start time");
            //     return;
            // }
        }

        setTimeRangeValueRaw(value);
    };
    const fetchAvailableLocations = async () => {
        const availableLocations = await axios.get('/available-locations').then((res) => res.data.data);
        console.log(availableLocations,"available vehicles");
        
        setAvailableLocations(availableLocations);
    };
    const location = window.location;
    useEffect(() => {
        fetchAvailableLocations();
        const searchParams = new URLSearchParams(location.search);
        const st = parseInt(searchParams.get('start') || defaultStartDate().toString());
        const en = parseInt(searchParams.get('end') || defaultEndDate().toString());
        // ms to moment
        const startDate = moment(st);
        const endDate = moment(en);
        if (startDate.isValid() && endDate.isValid()) {
            setDateRangeValue({
                startDate,
                endDate,
            });
        }
        // ms to time 10:00 AM
        const startTime = moment(st).format('hh:mm A');
        const endTime = moment(en).format('hh:mm A');
        if (startTime && endTime) {
            setTimeRangeValue({
                startTime,
                endTime,
            });
        }
    }, []);

    const value = {
        dateRangeValue,
        setDateRangeValue,
        timeRangeValue,
        setTimeRangeValue,
        bookingLocation,
        setBookingLocation,
        availableLocations,
        setAvailableLocations,
    };

    return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};

export const useDateContext = () => {
    var { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue, bookingLocation, setBookingLocation, availableLocations, setAvailableLocations }: any =
        React.useContext(DateContext);
    return { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue, bookingLocation, setBookingLocation, availableLocations, setAvailableLocations };
};
