import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";

const PageOffers: React.FC = () => (
    <div className="nc-PageOffers">
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            {/* HEADER */}
            <header className="text-center max-w-4xl mx-auto space-y-2">
                {/* <div className="flex justify-center">
                   <h1>Terms and Conditions</h1>
                </div> */}
                {/* <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                </span> */}
                <iframe src="https://docs.google.com/document/d/e/2PACX-1vRb_xOyUTX_xDN0I2BaVtPYQLZgB7uPgAnqDQn4P-8t0gils1ro8fuWpuIupEOKrA/pub?embedded=true" className="rounded-lg w-screen h-screen"></iframe>
                <div className="pt-8">
                    <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
                </div>
            </header>
        </div>
    </div>
);

export default PageOffers;
