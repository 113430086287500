import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { TaxonomyType } from 'data/types';
import React, { FC, useEffect } from 'react';
import SectionGridFilterCard from './SectionGridFilterCard';
import { Helmet } from 'react-helmet';
import heroRightImage from 'images/hero-right-car.png';
import { defaultEndDate, defaultStartDate, useDateContext } from 'contexts/DateContext';
import moment, { Moment } from 'moment';
import axios from '../../services/api';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import HeroSearchForm2MobileFactory from 'components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory';
import { message } from 'antd';

export interface ListingCarPageProps {
    className?: string;
}

export const useSearchDate = () => {
    const [startMs, setStartMs] = React.useState<number>(0);
    const [endMs, setEndMs] = React.useState<number>(0);
    const [bookingLocation, setBookingLocation] = React.useState<string>('Hyderabad, India');
    const { bookingLocation: bookingLocationContext, setBookingLocation: setBookingLocationContext } = useDateContext();
    const location = useLocation();
    useEffect(() => {
        console.log(location.search);
        const searchParams = new URLSearchParams(location.search);
        const st = parseInt(searchParams.get('start') || defaultStartDate().toString());
        const en = parseInt(searchParams.get('end') || defaultEndDate().toString());
        const loc = searchParams.get('location') || 'Hyderabad, India';
        if (st != startMs || en != endMs || loc != bookingLocation) {
            setStartMs(st);
            setEndMs(en);
            setBookingLocation(loc);
            setBookingLocationContext(loc);
        }
    });
    return [startMs, endMs, bookingLocation];
};

const DEMO_CATS: TaxonomyType[] = [
    {
        id: '1',
        href: '#',
        name: 'Enjoy the Beauty of Brazil ',
        taxonomy: 'category',
        count: 17288,
        thumbnail: 'https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        listingType: 'experiences',
    },
    {
        id: '2',
        href: '#',
        name: 'Enjoy the Beauty of Paris',
        taxonomy: 'category',
        count: 2118,
        thumbnail: 'https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        listingType: 'experiences',
    },
    {
        id: '3',
        href: '#',
        name: 'Enjoy the Beauty of Bangkok',
        taxonomy: 'category',
        count: 36612,
        thumbnail: 'https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        listingType: 'experiences',
    },
    {
        id: '5',
        href: '#',
        name: 'Enjoy the Beauty of Singapore',
        taxonomy: 'category',
        count: 188288,
        thumbnail: 'https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        listingType: 'experiences',
    },
    {
        id: '4',
        href: '#',
        name: 'Enjoy the Beauty of Seoul',
        taxonomy: 'category',
        count: 188288,
        thumbnail: 'https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        listingType: 'experiences',
    },
];

const getDate = (startDate: Moment, startTime: string) => {
    // if(startDate==null) return;
    console.log('startDate', startDate);
    console.log('startTime', startTime);

    var time = startTime.split(' ');
    var hour = time[0].split(':')[0];
    var min = time[0].split(':')[1];
    var ampm = time[1];
    if (ampm == 'PM') {
        hour = (parseInt(hour) + 12).toString();
    }

    var date = startDate?.toDate().setHours(parseInt(hour), parseInt(min), 0, 0);

    console.log(date);

    return date;
};

const ListingCarPage: FC<ListingCarPageProps> = ({ className = '' }) => {
    // useDateContext
    const { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue, bookingLocation, setBookingLocation } = useDateContext();
    // get start and end from url react
    const [vehicles, setVehicles] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [startMs, endMs, loc]: any = useSearchDate();

    const searchApi = () => {
        if (endMs < startMs || new Date(startMs) < new Date()) {
            message.error('Please select a valid date range');
            return;
        }
        axios
            .get('/search', {
                params: {
                    start: startMs,
                    end: endMs,
                    location: loc == 'Hyderabad, India' ? '' : loc,
                },
            })
            .then((res) => {
                console.log(res);
                setVehicles(res.data.data);
                // scroll to top #vehicles-list
                const el = document.getElementById('vehicles-list');
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        console.log('start');

        console.log('startDate', startMs);
        console.log('endDate', endMs);

        if (startMs && endMs) {
            searchApi();
        }
    }, [startMs, endMs, loc]);

    // const location = useLocation();
    // useEffect(() => {
    //   console.log(location.search)
    //   const searchParams = new URLSearchParams(location.search);
    //   const st = parseInt(searchParams.get("start") || "0")
    //   const en = parseInt(searchParams.get("end") || "0")
    //   if (st != startMs || en != endMs) {
    //     setStartMs(st);
    //     setEndMs(en);
    //   }
    // })

    console.log('dateRangeValue', getDate(dateRangeValue.startDate, timeRangeValue.startTime));

    return (
        <div className={`nc-ListingCarPage relative overflow-hidden ${className}`} data-nc-id="ListingCarPage">
            <Helmet>
                <title>StartRides || Bike Rentals</title>
            </Helmet>
            <BgGlassmorphism />

            <div className="container relative">
                {/* SECTION HERO */}
                <SectionHeroArchivePage
                    rightImage={heroRightImage}
                    currentPage="Cars"
                    currentTab="Cars"
                    listingType={
                        <>
                            <i className="text-2xl las la-motorcycle"></i>
                            <span className="ml-2.5">{vehicles.length} vehicles</span>
                        </>
                    }
                    vehicles={vehicles}
                    className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
                />

                {/* SECTION */}
                <SectionGridFilterCard className="pb-24 lg:pb-28" data={vehicles} />

                {/* SECTION 1 */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore top destination ✈"
            subHeading="Explore thousands of destinations around the world"
            categoryCardType="card4"
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle="style2"
            uniqueClassName="ListingCarPage"
          />
        </div> */}

                {/* SECTION */}
                {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}
            </div>
        </div>
    );
};

export default ListingCarPage;
