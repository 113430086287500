import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/no-offers.png";

const PageOffers: React.FC = () => (
    <div className="nc-PageOffers">
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            {/* HEADER */}
            <header className="text-center max-w-2xl mx-auto space-y-2">
                <div className="flex justify-center">
                    <img height={"200px"} alt="no-offers" src={I404Png} />

                </div>
                <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                    THERE ARE NO OFFERS CURRENTLY AVAILABLE.{" "}
                </span>
                <div className="pt-8">
                    <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
                </div>
            </header>
        </div>
    </div>
);

export default PageOffers;
