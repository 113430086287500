import { DateRage } from 'components/HeroSearchForm/StaySearchForm';
import { useDateContext } from 'contexts/DateContext';
import React, { useState } from 'react';
import GuestsInput, { GuestsObject } from './GuestsInput';
import LocationInput from './LocationInput';
import StayDatesRangeInput from './StayDatesRangeInput';

const StaySearchForm = () => {
    //
    const [fieldNameShow, setFieldNameShow] = useState<'location' | 'dates' | 'guests' | 'time' | 'time2'>('location');
    const { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue,bookingLocation:locationInputTo,setBookingLocation:setLocationInputTo } = useDateContext();
    // const [locationInputTo, setLocationInputTo] = useState('Hyderabad, India');

    const renderInputLocation = () => {
        const isActive = fieldNameShow === 'location';
        return (
            <div className={`w-full bg-white dark:bg-neutral-800 ${isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'}`}>
                {!isActive ? (
                    <div className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow('location')}>
                        <span className="text-neutral-400">Where</span>
                        <span>{locationInputTo || 'Location'}</span>
                    </div>
                ) : (
                    <>
                        <div className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow('location')}>
                            <span className="text-neutral-400">Where</span>
                            <span>{locationInputTo || 'Location'}</span>
                        </div>
                        <LocationInput
                            defaultValue={locationInputTo}
                            onChange={(value) => {
                                setLocationInputTo(value);
                                setFieldNameShow('dates');
                            }}
                        />
                    </>
                )}
            </div>
        );
    };

    const renderInputDates = () => {
        const isActive = fieldNameShow === 'dates';
        console.log(dateRangeValue);
        const startDateString = dateRangeValue.startDate?.format('MMM DD');
        console.log(startDateString);

        const endDateString =
            dateRangeValue.endDate?.get('month') !== dateRangeValue.startDate?.get('month')
                ? dateRangeValue.endDate?.format('MMM DD')
                : dateRangeValue.endDate?.format('DD');
        const dateSelected = startDateString && endDateString ? `${startDateString} - ${endDateString}` : `${startDateString || endDateString || ''}`;
        return (
            <div
                className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
                    isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
                }`}
            >
                {!isActive ? (
                    <button className={`w-full flex justify-between text-sm font-medium p-4  `} onClick={() => setFieldNameShow('dates')}>
                        <span className="text-neutral-400">When</span>
                        <span>{dateSelected || 'Add date'}</span>
                    </button>
                ) : (
                    <StayDatesRangeInput defaultValue={dateRangeValue} onChange={setDateRangeValue} />
                )}
            </div>
        );
    };

    // renderInputTime dropdown from and dropdown to time 8 am to 8 pm
    const renderInputTime = () => {
        const isActive = fieldNameShow === 'time';
        return (
            <div className={`w-full bg-white dark:bg-neutral-800 ${isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'}`}>
                {!isActive ? (
                    <div className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow('time')}>
                        <span className="text-neutral-400">From</span>
                        <span className="text-neutral-400">{timeRangeValue.startTime}</span>
                    </div>
                ) : (
                    <div className="w-full flex justify-between text-sm font-medium p-4">
                        <span className="text-neutral-400">From</span>
                        {
                            // dropdown from time 8 am to 8 pm
                            <select
                                className="ml-2"
                                value={timeRangeValue.startTime}
                                onChange={(e) => {
                                    setTimeRangeValue({ ...timeRangeValue, startTime: e.target.value });
                                }}
                                style={{ width: 140 }}
                            >
                                <option>8:00 AM</option>
                                <option>9:00 AM</option>
                                <option>10:00 AM</option>
                                <option>11:00 AM</option>
                                <option>12:00 PM</option>
                                <option>1:00 PM</option>
                                <option>2:00 PM</option>
                                <option>3:00 PM</option>
                                <option>4:00 PM</option>
                                <option>5:00 PM</option>
                                <option>6:00 PM</option>
                                <option>7:00 PM</option>
                                <option>8:00 PM</option>
                            </select>
                        }
                    </div>
                )}
            </div>
        );
    };
    const renderInputTime2 = () => {
        const isActive = fieldNameShow === 'time2';
        return (
            <div className={`w-full bg-white dark:bg-neutral-800 ${isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'}`}>
                {!isActive ? (
                    <div className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow('time2')}>
                        <span className="text-neutral-400">To</span>
                        <span className="text-neutral-400">{timeRangeValue.endTime}</span>
                    </div>
                ) : (
                    <div className="w-full flex justify-between text-sm font-medium p-4">
                        <span className="text-neutral-400">To</span>
                        {
                            // dropdown from time 8 am to 8 pm
                            <select
                                className="ml-2"
                                value={timeRangeValue.endTime}
                                onChange={(e) => {
                                    setTimeRangeValue({ ...timeRangeValue, endTime: e.target.value });
                                }}
                                style={{ width: 140 }}
                            >
                                <option>8:00 AM</option>
                                <option>9:00 AM</option>
                                <option>10:00 AM</option>
                                <option>11:00 AM</option>
                                <option>12:00 PM</option>
                                <option>1:00 PM</option>
                                <option>2:00 PM</option>
                                <option>3:00 PM</option>
                                <option>4:00 PM</option>
                                <option>5:00 PM</option>
                                <option>6:00 PM</option>
                                <option>7:00 PM</option>
                                <option>8:00 PM</option>
                            </select>
                        }
                    </div>
                )}
            </div>
        );
    };

    // const renderInputGuests = () => {
    //   const isActive = fieldNameShow === "guests";
    //   let guestSelected = "";
    //   if (guestInput.guestAdults || guestInput.guestChildren) {
    //     const guest =
    //       (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
    //     guestSelected += `${guest} guests`;
    //   }

    //   if (guestInput.guestInfants) {
    //     guestSelected += `, ${guestInput.guestInfants} infants`;
    //   }

    //   return (
    //     <div
    //       className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
    //         ? "rounded-2xl shadow-lg"
    //         : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
    //         }`}
    //     >
    //       {!isActive ? (
    //         <button
    //           className={`w-full flex justify-between text-sm font-medium p-4`}
    //           onClick={() => setFieldNameShow("guests")}
    //         >
    //           <span className="text-neutral-400">Who</span>
    //           <span>{guestSelected || `Add guests`}</span>
    //         </button>
    //       ) : (
    //         <GuestsInput defaultValue={guestInput} onChange={setGuestInput} />
    //       )}
    //     </div>
    //   );
    // };

    return (
        <div>
            <div className="w-full space-y-5">
                {/*  */}
                {renderInputLocation()}
                {/*  */}
                {renderInputDates()}
                {/*  */}
                {renderInputTime()}
                {/*  */}
                {renderInputTime2()}
                {/* {renderInputGuests()} */}
            </div>
        </div>
    );
};

export default StaySearchForm;
