import { BookingContext, BookingProvider } from "contexts/BookingContext";
import { DateProvider } from "contexts/DateContext";
import { UserProvider } from "contexts/UserContext";
import React, { useEffect } from "react";
import MyRouter from "routers/index";
function App() {

  return (
    <div className=" min-h-full bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <UserProvider>
        <BookingProvider>

          <DateProvider>
            <MyRouter />
          </DateProvider>
        </BookingProvider>
      </UserProvider>
    </div>
  );
}

export default App;
