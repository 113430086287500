import React, { useContext, useState } from 'react';
import { FC } from 'react';
import { useEffect } from 'react';
import ClearDataButton from './ClearDataButton';
import { useRef } from 'react';
import { useDateContext } from 'contexts/DateContext';

export interface LocationInputProps {
    defaultValue: string;
    onChange?: (value: string) => void;
    onInputDone?: (value: string) => void;
    placeHolder?: string;
    desc?: string;
    className?: string;
    disabled?: boolean;
    autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
    defaultValue,
    autoFocus = false,
    onChange,
    onInputDone,
    disabled,
    placeHolder = 'Location',
    desc = '',
    className = 'nc-flex-1.5',
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // const [value, setValue] = useState(defaultValue);
    const { bookingLocation: value, setBookingLocation: setValue,availableLocations }: any = useDateContext();
    const [showPopover, setShowPopover] = useState(autoFocus);
    const [openPicker, setOpenPicker] = useState(false);


    console.log(availableLocations);

    useEffect(() => {
        console.log(setValue);

        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        setShowPopover(!disabled && autoFocus);
    }, [autoFocus]);

    useEffect(() => {
        if (eventClickOutsideDiv) {
            document.removeEventListener('click', eventClickOutsideDiv);
        }
        showPopover && document.addEventListener('click', eventClickOutsideDiv);
        return () => {
            document.removeEventListener('click', eventClickOutsideDiv);
        };
    }, [showPopover]);

    useEffect(() => {
        if (showPopover && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showPopover]);

    const eventClickOutsideDiv = (event: MouseEvent) => {
        if (!containerRef.current) return;
        // CLICK IN_SIDE
        if (!showPopover || containerRef.current.contains(event.target as Node)) {
            return;
        }
        // CLICK OUT_SIDE
        setShowPopover(false);
    };

    const handleSelectLocation = (item: string) => {
        setValue(item);
        onInputDone && onInputDone(item);
        setShowPopover(false);
    };

    const renderRecentSearches = () => {
        return (
            <>
                <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">Recent searches</h3>
                <div className="mt-2">
                    {['Hamptons, Suffolk County, NY', 'Las Vegas, NV, United States', 'Ueno, Taito, Tokyo', 'Ikebukuro, Toshima, Tokyo'].map((item) => (
                        <span
                            onClick={() => handleSelectLocation(item)}
                            key={item}
                            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                        >
                            <span className="block text-neutral-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 sm:h-6 w-4 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </span>
                            <span className=" block font-medium text-neutral-700 dark:text-neutral-200">{item}</span>
                        </span>
                    ))}
                </div>
            </>
        );
    };

    const renderSearchValue = () => {
        return (
            <>
                {['Ha Noi, Viet Nam', 'San Diego, CA', 'Humboldt Park, Chicago, IL', 'Bangor, Northern Ireland'].map((item) => (
                    <span
                        onClick={() => handleSelectLocation(item)}
                        key={item}
                        className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                    >
                        <span className="block text-neutral-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        </span>
                        <span className="block font-medium text-neutral-700 dark:text-neutral-200">{item}</span>
                    </span>
                ))}
            </>
        );
    };

    return (
        <>
            <div className={`relative flex ${className}`} ref={containerRef}>
                <div
                    onClick={() => setOpenPicker(!openPicker)}
                    className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
                        showPopover ? 'nc-hero-field-focused' : ''
                    }`}
                >
                    <div className="text-neutral-300 dark:text-neutral-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="nc-icon-field" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </div>
                    <div className="flex-grow">
                        <span
                            className={`block bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                            // placeholder={placeHolder}
                            children={value}
                            // disabled={disabled}
                            // autoFocus={showPopover}
                            // onChange={(e) => {
                            //   setValue(e.currentTarget.value);
                            //   onChange && onChange(e.currentTarget.value);
                            // }}
                            // ref={inputRef}
                        />
                        <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
                        </span>
                        {/* {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                onChange && onChange("");
              }}
            />
          )} */}
                    </div>
                </div>
                {/* {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : renderRecentSearches()}
        </div>
      )} */}
            </div>
            {
                // display options Adibatla, Hyderabad, and LB Nagar, Hyderabad
                openPicker && (
                    <div className=" ml-9 absolute top-full left-0 w-full bg-white dark:bg-neutral-800 rounded-b-[40px] xl:rounded-b-[49px] shadow-xl dark:shadow-2xl ">
                        {/* <div
                            className="py-1.5 px-8 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 dark:text-white text-black"
                            onClick={(e) => {
                                setValue('Adibatla, Hyderabad');
                                setOpenPicker(false);
                            }}
                        >
                            Adibatla, Hyderabad
                        </div> */}

                        {availableLocations?.map((location: any) => {
                            return (
                                <div
                                    className="py-1.5 px-8 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 dark:text-white text-black"
                                    onClick={(e) => {
                                        setValue(location);
                                        setOpenPicker(false);
                                    }}
                                >
                                    {location}
                                </div>
                            );
                        })}

                        {/* <div
                            className="py-1.5 px-8 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 dark:text-white text-black"
                            onClick={(e) => {
                                setValue('LB Nagar, Meerpet, Hyderbad');
                                setOpenPicker(false);
                            }}
                        >
                            LB Nagar, Meerpet, Hyderbad
                        </div> */}
                    </div>
                )
            }
        </>
    );
};

export default LocationInput;
