import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<any> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    vehicle,
    _id,
    actualPrice,
    amount,
    discount,
    endDate,
    startDate,
    status,
    reserveAmount,
    refundAmount,
    addonsAmount,
    addons,
    payments
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${_id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={vehicle[0].images}
          href={`/bookings/${_id}`}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
          </span>
          <div className="flex items-center space-x-2">
            <Link to={`/listing/${vehicle[0]._id}`}>
              <h2
                className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                  }`}
              >
                <span className="line-clamp-1">{vehicle[0].name}</span>
              </h2>
            </Link>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {/* {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
             */}
            {
              // date hh:mm day dd Mon (startDate), hh:mm day dd Mon(endDate)
              <span>
                {new Date(startDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} {new Date(startDate).toLocaleString('en-US', { weekday: 'short', day: 'numeric', month: 'short' })}
                {` - `}
                {new Date(endDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} {new Date(endDate).toLocaleString('en-US', { weekday: 'short', day: 'numeric', month: 'short' })}
              </span>

            }
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {`₹ `}
            {amount}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">

              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/bookings/${_id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;
