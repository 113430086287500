import React, { } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";


export const UserContext = React.createContext({});


const auth = getAuth();

export const logout = async () => {
    await signOut(auth);
}


export const UserProvider = ({ children }: any) => {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            console.log(user);

            setUser(user);
            auth.currentUser?.getIdToken(true).then((idToken) => {
                console.log(idToken);
            }).catch((error) => {
                // Handle error
            })
            setLoading(false);
        });
        // get token
        return unsubscribe;
    }, []);

    const value = { user };

    return (
        <UserContext.Provider value={value}>
            {!loading && children}
        </UserContext.Provider>
    );
};
